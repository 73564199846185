import React, { useState, useEffect } from 'react';

import { CareersBlockEntry } from '@/types/CareersBlock';
import Button from './Button';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';

import classNames from 'classnames/bind';
import style from './CareersBlock.module.scss';
const cx = classNames.bind(style);

type Team = {
    title: string;
    postings: JobPost[];
};

type JobPost = {
    applyUrl: string;
    categories: JobPostCategories;
    createdAt: number;
    description: string;
    descriptionPlain: string;
    hostedUrl: string;
    id: string;
    lists: JobPostList[];
    text: string;
};

type JobPostCategories = {
    commitment: string;
    location: string;
    team: string;
};

type JobPostList = {
    content: string;
    text: string;
};

export type CareersBlockProps = {
    entry: CareersBlockEntry;
};

export const CareersBlock: React.FC<CareersBlockProps> = (props) => {
    const [teams, setTeams] = useState<Team[]>([]);

    useEffect(() => {
        void getJobPosts();
    }, []);

    const getJobPosts = async () => {
        const { leverApiUrl } = props.entry.fields;

        try {
            const response = await fetch(leverApiUrl);
            const teamsResponse = (await response.json()) as Team[];
            setTeams(teamsResponse);
        } catch (e) {
            console.error('Error retrieving careers!', e);
        }
    };

    const { headline, ctaLink, ctaTitle } = props.entry.fields;

    return (
        <div className={cx('careers-block', 'inverse')}>
            <Container>
                {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                {teams &&
                    teams.map((team, i) => (
                        <div key={i} className={cx('team')}>
                            {team.title && <h2 className={cx('team-name')}>{team.title}</h2>}
                            {team.postings &&
                                team.postings.map((jobPost) => (
                                    <Grid key={jobPost.id} className={cx('job')}>
                                        <div className={cx('job-content')}>
                                            {jobPost.text && (
                                                <h3 className={cx('job-title')}>
                                                    <a href={jobPost.hostedUrl}>{jobPost.text}</a>
                                                </h3>
                                            )}
                                            {jobPost.categories && jobPost.categories.location && (
                                                <p className={cx('job-location')}>{jobPost.categories.location}</p>
                                            )}
                                            {/* {jobPost.descriptionPlain && <p className={cx('job-description')}>{jobPost.descriptionPlain}</p>} */}
                                        </div>
                                        {jobPost.hostedUrl && (
                                            <Button
                                                className={cx('job-apply')}
                                                color="light"
                                                href={jobPost.hostedUrl}
                                                target={'_blank'}
                                            >
                                                Learn More
                                            </Button>
                                        )}
                                    </Grid>
                                ))}
                        </div>
                    ))}
                {ctaLink && ctaTitle && (
                    <div className={cx('cta')}>
                        <Button color="light" href={ctaLink}>
                            {ctaTitle}
                        </Button>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default CareersBlock;
