import React from 'react';

import { HeroBlockEntry } from '@/types/HeroBlock';
import Button from './Button';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import SubscribeForm from './SubscribeForm';
import Topline from './Topline';

import Connect0 from '../images/shapes/connect-0.svg';

import classNames from 'classnames/bind';
import style from './HeroCallToAction.module.scss';
const cx = classNames.bind(style);

export type HeroCallToActionProps = {
    entry: HeroBlockEntry;
};

export const HeroCallToAction: React.FC<HeroCallToActionProps> = (props) => {
    const { attribution, attributionLink, image, topline, headline, copy, ctaLink, ctaTitle, signupForm } =
        props.entry.fields;

    return (
        <div className={cx('hero-cta', 'inverse')}>
            {!image && <Connect0 className={cx('connect')} />}
            <Container className={cx('hero-content-container')}>
                <Grid>
                    {image && (
                        <div className={cx('hero-image')}>
                            <Image
                                className={cx('hero-image-img')}
                                src={image.fields.file.url}
                                alt={image.fields.title}
                                width={960}
                                size={image.fields.file.details.image}
                                fit="fill"
                                focus="faces"
                            />
                            {attribution && (
                                <p className={cx('attribution')}>
                                    {attributionLink ? (
                                        <a className={cx('attribution-link')} href={attributionLink}>
                                            {attribution}
                                        </a>
                                    ) : (
                                        attribution
                                    )}
                                </p>
                            )}
                        </div>
                    )}
                    <div className={cx('hero-content')}>
                        {topline && <Topline>{topline}</Topline>}
                        {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                        {copy && <RichText document={copy} />}
                        {signupForm && <SubscribeForm />}
                        {ctaLink && ctaTitle && (
                            <Button href={ctaLink} inverseColor="white">
                                {ctaTitle}
                            </Button>
                        )}
                    </div>
                </Grid>
            </Container>
        </div>
    );
};

export default HeroCallToAction;
