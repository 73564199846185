import React from 'react';

import { ArtistEntry } from '@/types/Artist';
import { Asset } from '@/types/Contentful';
import { ShowcaseBlockEntry } from '@/types/ShowcaseBlock';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import Row from './Row';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './ShowcaseBlock.module.scss';
const cx = classNames.bind(style);

export type ShowcaseBlockProps = {
    entry: ShowcaseBlockEntry;
};

export const ShowcaseBlock: React.FC<ShowcaseBlockProps> = (props) => {
    const { headline, images, topline } = props.entry.fields;
    const artist: ArtistEntry | undefined = images && images.length ? images[0].fields.artist : undefined;
    const artistPhoto: Asset | undefined = artist ? artist.fields.photo : undefined;

    const squareAspect = {
        height: 1,
        width: 1,
    };

    return (
        <div className={cx('showcase-block')}>
            <Container>
                <Row align={'start'} gap="xs" className={cx('showcase-artist')}>
                    {topline && <Topline>{topline}</Topline>}
                    {headline && <Headline className={cx('showcase-artist-name')}>{headline}</Headline>}
                    {artist && artist.fields.about && <RichText className={cx('bio')} document={artist.fields.about} />}
                    {artistPhoto && (
                        <Image
                            className={cx('showcase-artist-photo')}
                            src={artistPhoto.fields.file.url}
                            alt={artistPhoto.fields.title}
                            width={450}
                            height={450}
                            size={squareAspect}
                            fit="fill"
                            focus="faces"
                            round={true}
                        />
                    )}
                </Row>
                <Grid className={cx('showcase-grid')}>
                    {images &&
                        images.map((image) => (
                            <div key={image.sys.id} className={cx('showcase-item')}>
                                {image.fields.image && (
                                    <Image
                                        className={cx('showcase-image')}
                                        src={image.fields.image.fields.file.url}
                                        alt={image.fields.image.fields.title}
                                        size={image.fields.image.fields.file.details.image}
                                    />
                                )}
                            </div>
                        ))}
                </Grid>
            </Container>
        </div>
    );
};

export default ShowcaseBlock;
