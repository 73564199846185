import React from 'react';

import { HeroBlockEntry } from '@/types/HeroBlock';
import Button from './Button';
import Container from './Container';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import SubscribeForm from './SubscribeForm';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './HeroSplash.module.scss';
const cx = classNames.bind(style);

export type HeroSplashProps = {
    entry: HeroBlockEntry;
};

export const HeroSplash: React.FC<HeroSplashProps> = (props) => {
    const { attribution, attributionLink, image, topline, headline, copy, ctaLink, ctaTitle, signupForm } =
        props.entry.fields;

    return (
        <>
            <div className={cx('hero-splash', 'inverse')}>
                {image && (
                    <Image
                        className={cx('hero-image')}
                        src={image.fields.file.url}
                        alt={image.fields.title}
                        width={2880}
                        fit="fill"
                        focus="faces"
                    />
                )}
                <Container className={cx('hero-content-container')}>
                    <div className={cx('hero-content')}>
                        {topline && <Topline>{topline}</Topline>}
                        {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                        {copy && <RichText document={copy} />}
                        {signupForm === true && <SubscribeForm />}
                        {ctaLink && ctaTitle && (
                            <Button href={ctaLink} color="light">
                                {ctaTitle}
                            </Button>
                        )}
                    </div>
                </Container>
            </div>
            {attribution && (
                <p className={cx('attribution')}>
                    {attributionLink ? (
                        <a className={cx('attribution-link')} href={attributionLink}>
                            {attribution}
                        </a>
                    ) : (
                        attribution
                    )}
                </p>
            )}
        </>
    );
};

export default HeroSplash;
