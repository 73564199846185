import React from 'react';

import { BackgroundFigureBlockEntry } from '@/types/BackgroundFigureBlock';
import BackgroundFigure from './BackgroundFigure';

export type BackgroundFigureBlockProps = {
    entry: BackgroundFigureBlockEntry;
};

export const BackgroundFigureBlock: React.FC<BackgroundFigureBlockProps> = (props) => {
    const {
        color,
        disableAnimation,
        figure,
        flipHorizontal,
        flipVertical,
        horizontalOffset,
        horizontalPosition,
        layerAdjust,
        rotation,
        verticalOffset,
        verticalPosition,
        width,
    } = props.entry.fields;

    return (
        <BackgroundFigure
            color={color}
            disableAnimation={disableAnimation}
            figure={figure}
            flipHorizontal={flipHorizontal}
            flipVertical={flipVertical}
            horizontalOffset={horizontalOffset}
            horizontalPosition={horizontalPosition}
            layerAdjust={layerAdjust}
            rotation={rotation}
            verticalOffset={verticalOffset}
            verticalPosition={verticalPosition}
            width={width}
        />
    );
};

export default BackgroundFigureBlock;
