import React, { useState } from 'react';

import { AnalyticsEvent, trackEvent } from '../analytics';
import Button from './Button';

import ArrowDown from '../images/icons/arrow-down.svg';

import classNames from 'classnames/bind';
import style from './SubscribeForm.module.scss';
const cx = classNames.bind(style);

export type SubscribeFormProps = {
    className?: string;
};

export const SubscribeForm: React.FC<SubscribeFormProps> = (props) => {
    const [email, setEmail] = useState('');
    const [isFailed, setIsFailed] = useState(false);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!email) {
            setIsFailed(true);
            setTimeout(() => setIsFailed(false), 600);
            return;
        }

        // Track the signup conversion in Segment
        trackEvent(AnalyticsEvent.SignupWithEmail);

        location.href = `https://app.facet.ai/signup?e=${encodeURIComponent(email)}`;
    };

    const { className } = props;

    return (
        <form className={cx('subscribe-form', { 'is-failed': isFailed }, className)} onSubmit={handleSubmit}>
            <input
                className={cx('email')}
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                aria-label="Enter email"
                placeholder="Enter email"
                autoComplete="email"
            />
            <Button className={cx('subscribe')}>
                <div className={cx('btn-inner')}>
                    <span className={cx('subscribe-text')}>Sign Up </span>
                    <ArrowDown className={cx('btn-arrow')} />
                </div>
            </Button>
        </form>
    );
};

export default SubscribeForm;
