import Helmet from 'react-helmet';
import React from 'react';

import { GatsbyPageProps } from '@/types/Gatsby';
import { PageEntry, PageBlock } from '@/types/Page';
import Blocks from '../components/Blocks';
import FeaturesContext from '../contexts/FeaturesContext';
import Grid from '../components/Grid';
import Layout from '../components/Layout';
import PageContext, { PageContextState } from '../contexts/PageContext';
import Sidebar from '../components/Sidebar';

import classNames from 'classnames/bind';
import style from './Page.module.scss';
const cx = classNames.bind(style);

export const Page: React.FC<GatsbyPageProps<PageEntry>> = (props) => {
    const { browserTitle, title, blocks, inverseHeader, slug, sidebarLinks } = props.pageContext.entry.fields;

    // Move the first block on the home page outside of the `.main` container
    //  - The home page scrolling animation contains `position:sticky`, which doesn't work inside `overflow:hidden`
    //  - We need `overflow:hidden` on the `.main` container to avoid scrolling issues with background figures
    //  - This is a bit messy but it does allow us to use sticky positioning while keeping the overflow hidden
    const hasHomeBlock = !!(slug === 'home' && blocks);
    const preMainContent: React.ReactNode = hasHomeBlock ? <Blocks blocks={[blocks![0]]} /> : null;
    const pageBlocks: PageBlock[] | undefined = hasHomeBlock ? blocks!.slice(1) : blocks;

    const isOnePage = !!(slug === 'call-for-artists');
    const pageContext = (): PageContextState => ({ inverseHeader, isOnePage });

    return (
        <PageContext.Provider value={pageContext()}>
            <FeaturesContext.Provider value={props.pageContext.features || []}>
                <Layout preMainContent={preMainContent} hasStickyContent={!!sidebarLinks}>
                    <Helmet title={browserTitle || title} />
                    <Grid className={cx({ 'has-sidebar': !!sidebarLinks })}>
                        {sidebarLinks && <Sidebar className={cx('sidebar')} links={sidebarLinks} />}
                        <section className={cx('main')}>{pageBlocks && <Blocks blocks={pageBlocks} />}</section>
                    </Grid>
                </Layout>
            </FeaturesContext.Provider>
        </PageContext.Provider>
    );
};

export default Page;
