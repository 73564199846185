import { Link } from 'gatsby';
import React from 'react';

import { JobsBlockEntry } from '@/types/JobsBlock';
import Button from './Button';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './JobsBlock.module.scss';
const cx = classNames.bind(style);

export type JobsBlockProps = {
    entry: JobsBlockEntry;
};

export const JobsBlock: React.FC<JobsBlockProps> = (props) => {
    const { topline, headline, jobs, ctaTitle, ctaLink } = props.entry.fields;

    const shownCategories: string[] = [];

    return (
        <div className={cx('jobs-block', 'inverse')}>
            {topline && <Topline>{topline}</Topline>}
            {headline && <Headline className={cx('headline')}>{headline}</Headline>}
            <Container>
                <Grid className={cx('jobs-grid')}>
                    {!!jobs?.items &&
                        jobs.items.map((job) => {
                            let categoryHeader: React.ReactNode = null;
                            if (job.fields.category && !shownCategories.includes(job.fields.category)) {
                                shownCategories.push(job.fields.category);
                                categoryHeader = <h2 className={cx('category')}>{job.fields.category}</h2>;
                            }

                            return (
                                <React.Fragment key={job.sys.id}>
                                    {categoryHeader}
                                    <Grid className={cx('job')}>
                                        <div className={cx('job-content')}>
                                            <h3 className={cx('job-title')}>
                                                <Link to={`/jobs/${job.fields.slug}`}>{job.fields.title}</Link>
                                            </h3>
                                            <p className={cx('job-meta')}>
                                                {job.fields.location && <span>{job.fields.location}</span>}
                                                {job.fields.type && <span>{job.fields.type}</span>}
                                            </p>
                                        </div>
                                        <div className={cx('job-apply')}>
                                            <Button align="right" color="light" to={`/jobs/${job.fields.slug}`}>
                                                Learn More
                                            </Button>
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                </Grid>
                {ctaLink && ctaTitle && (
                    <div className={cx('cta')}>
                        <Button href={ctaLink}>{ctaTitle}</Button>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default JobsBlock;
