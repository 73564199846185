import { Link } from 'gatsby';
import React from 'react';

import { SidebarLink } from '@/types/Page';

import classNames from 'classnames/bind';
import style from './Sidebar.module.scss';
const cx = classNames.bind(style);

export type SidebarProps = {
    className?: string;
    links: SidebarLink[];
};

/**
 * Simple utility component for controlling vertical margins between children
 */
export const Sidebar: React.FC<SidebarProps> = (props) => {
    const { className, links } = props;

    return (
        <aside className={cx('sidebar', className)} role="complimentary">
            <ul className={cx('menu')}>
                {links.map((link, i) => (
                    <li key={i}>
                        <Link to={`${link.slug}${link.bookmark ? `#${link.bookmark}` : ''}`} className={cx('link')}>
                            {link.title}
                        </Link>
                        {link.links && !!link.links.length && (
                            <ul className={cx('sub')}>
                                {link.links.map((subLink, i) => (
                                    <li key={i}>
                                        <Link
                                            to={`${subLink.slug}${subLink.bookmark ? `#${subLink.bookmark}` : ''}`}
                                            className={cx('sub-link')}
                                        >
                                            {subLink.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </aside>
    );
};

export default Sidebar;
