import React from 'react';

import { Asset } from '@/types/Contentful';
import { TeammateEntry } from '@/types/Teammate';
import { TeammatesBlockEntry } from '@/types/TeammatesBlock';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './TeammatesBlock.module.scss';
const cx = classNames.bind(style);

export type TeammatesBlockProps = {
    entry: TeammatesBlockEntry;
};

export const TeammatesBlock: React.FC<TeammatesBlockProps> = (props) => {
    const { headline, teammates } = props.entry.fields;

    const renderName = (teammate: TeammateEntry) => {
        const name = <h3 className={cx('teammate-name')}>{teammate.fields.name}</h3>;

        if (teammate.fields.link) {
            return (
                <a className={cx('link')} href={teammate.fields.link} target="_blank" rel="noreferrer">
                    {name}
                </a>
            );
        }

        return name;
    };

    const isLandscape = (photo?: Asset) => {
        if (!photo || !photo.fields.file.details.image) {
            return false;
        }

        const { height, width } = photo.fields.file.details.image;
        return width > height;
    };

    return (
        <div className={cx('teammates-block')}>
            <Container>
                {headline && <Headline className={cx('title')}>{headline}</Headline>}
                <Grid className={cx('teammates-grid')}>
                    {teammates &&
                        teammates.map((teammate) => (
                            <div
                                key={teammate.sys.id}
                                id={`teammate-${teammate.fields.slug}`}
                                className={cx(
                                    'teammate',
                                    isLandscape(teammate.fields.photo) ? 'is-landscape' : 'is-portrait'
                                )}
                            >
                                {teammate.fields.photo && (
                                    <Image
                                        className={cx('teammate-photo')}
                                        src={teammate.fields.photo.fields.file.url}
                                        alt={teammate.fields.photo.fields.title}
                                        width={1440}
                                        size={teammate.fields.photo.fields.file.details.image}
                                    />
                                )}
                                {teammate.fields.title && <Topline>{teammate.fields.title}</Topline>}
                                {renderName(teammate)}
                                {teammate.fields.bio && (
                                    <RichText className={cx('bio')} document={teammate.fields.bio} />
                                )}
                            </div>
                        ))}
                </Grid>
            </Container>
        </div>
    );
};

export default TeammatesBlock;
