import React from 'react';

import { InvestorsBlockEntry } from '@/types/InvestorsBlock';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Image from './Image';

import classNames from 'classnames/bind';
import style from './InvestorsBlock.module.scss';
const cx = classNames.bind(style);

export type InvestorsBlockProps = {
    entry: InvestorsBlockEntry;
};

export const InvestorsBlock: React.FC<InvestorsBlockProps> = (props) => {
    const { headline, investors } = props.entry.fields;

    return (
        <div className={cx('investors-block')}>
            <Container>
                {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                <Grid className={cx('investors-grid')}>
                    {investors &&
                        investors.map((investor) => (
                            <div
                                key={investor.sys.id}
                                id={`investor-${investor.fields.slug}`}
                                className={cx('investor')}
                            >
                                {investor.fields.image && (
                                    <a href={investor.fields.link || '#'} className={cx('investor-link')}>
                                        <Image
                                            className={cx('investor-image')}
                                            src={investor.fields.image.fields.file.url}
                                            alt={investor.fields.image.fields.title}
                                        />
                                    </a>
                                )}
                            </div>
                        ))}
                </Grid>
            </Container>
        </div>
    );
};

export default InvestorsBlock;
