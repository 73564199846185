import React from 'react';

import { BlogBlockEntry } from '@/types/BlogBlock';
import Button from './Button';
import Container from './Container';
import Excerpt from './Excerpt';
import Grid from './Grid';
import Headline from './Headline';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './BlogBlock.module.scss';
const cx = classNames.bind(style);

export type BlogBlockProps = {
    entry: BlogBlockEntry;
};

export const BlogBlock: React.FC<BlogBlockProps> = (props) => {
    const { topline, headline, ctaTitle, ctaLink } = props.entry.fields;

    const displayPosts = () => {
        const { posts, limit } = props.entry.fields;
        return !limit ? (posts ? posts.items : []) : posts ? posts.items.slice(0, limit) : [];
    };

    return (
        <div className={cx('blog-block')}>
            {topline && <Topline>{topline}</Topline>}
            {headline && <Headline className={cx('title')}>{headline}</Headline>}
            <Container>
                <Grid className={cx('blog-grid')}>
                    {displayPosts().map((post) => (
                        <Excerpt
                            key={post.sys.id}
                            title={post.fields.title}
                            author={post.fields.author}
                            excerpt={post.fields.synopsis}
                            thumbnail={post.fields.thumbnail}
                            attribution={post.fields.attribution}
                            attributionLink={post.fields.attributionLink}
                            link={post.fields.externalLink || undefined}
                            path={post.fields.externalLink ? undefined : `/blog/${post.fields.slug}`}
                        />
                    ))}
                </Grid>
                {ctaLink && ctaTitle && (
                    <div className={cx('cta')}>
                        <Button href={ctaLink}>{ctaTitle}</Button>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default BlogBlock;
