import { Link } from 'gatsby';
import React from 'react';

import { Asset } from '@/types/Contentful';
import { AuthorEntry } from '@/types/Author';
import Image from './Image';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './Excerpt.module.scss';
const cx = classNames.bind(style);

export type ExcerptProps = {
    attribution?: string;
    attributionLink?: string;
    author?: AuthorEntry;
    excerpt?: string;
    link?: string;
    path?: string;
    thumbnail?: Asset;
    title: string;
};

export const Excerpt: React.FC<ExcerptProps> = (props) => {
    const { attribution, attributionLink, author, excerpt, link, path, thumbnail, title } = props;

    const linkify = (children: React.ReactNode, className?: string) => {
        if (path) {
            return (
                <Link to={path} className={className}>
                    {children}
                </Link>
            );
        } else {
            return (
                <a href={link} className={className}>
                    {children}
                </a>
            );
        }
    };

    return (
        <div className={cx('excerpt')}>
            {thumbnail &&
                linkify(
                    <Image
                        src={thumbnail.fields.file.url}
                        alt={thumbnail.fields.title}
                        width={600}
                        size={thumbnail.fields.file.details.image}
                        fit="fill"
                        focus="faces"
                    />,
                    cx('thumb')
                )}
            {thumbnail && attribution && (
                <p className={cx('attribution')}>
                    {attributionLink ? (
                        <a className={cx('attribution-link')} href={attributionLink}>
                            {attribution}
                        </a>
                    ) : (
                        attribution
                    )}
                </p>
            )}
            {author && author.fields && <Topline>{author.fields.name}</Topline>}
            <h2 className={cx('title')}>{linkify(title, cx('link'))}</h2>
            {excerpt && <p className={cx('description')}>{excerpt}</p>}
        </div>
    );
};

export default Excerpt;
