import { motion, useTransform, useViewportScroll, useSpring, MotionStyle } from 'framer-motion';
import React, { useRef, useState, useLayoutEffect } from 'react';

import Circle from '../images/shapes/circle.svg';
import Connect0 from '../images/shapes/connect-0.svg';
import Connect90 from '../images/shapes/connect-90.svg';
import Connect180 from '../images/shapes/connect-180.svg';
import Connect270 from '../images/shapes/connect-270.svg';
import Dial0 from '../images/shapes/dial-0.svg';
import Dial90 from '../images/shapes/dial-90.svg';
import Dial180 from '../images/shapes/dial-180.svg';
import Dial270 from '../images/shapes/dial-270.svg';
import GoldenSection0 from '../images/shapes/golden-section-0.svg';
import GoldenSection90 from '../images/shapes/golden-section-90.svg';
import GoldenSection180 from '../images/shapes/golden-section-180.svg';
import GoldenSection270 from '../images/shapes/golden-section-270.svg';
import Swoop0 from '../images/shapes/swoop-0.svg';
import Swoop90 from '../images/shapes/swoop-90.svg';
import Swoop180 from '../images/shapes/swoop-180.svg';
import Swoop270 from '../images/shapes/swoop-270.svg';

import classNames from 'classnames/bind';
import style from './BackgroundFigure.module.scss';
const cx = classNames.bind(style);

const FIGURES = {
    Circle,
    Connect0,
    Connect90,
    Connect180,
    Connect270,
    Dial0,
    Dial90,
    Dial180,
    Dial270,
    GoldenSection0,
    GoldenSection90,
    GoldenSection180,
    GoldenSection270,
    Swoop0,
    Swoop90,
    Swoop180,
    Swoop270,
};

const getFigure = (figureName: string, rotation: string = '0') => {
    return FIGURES[`${figureName}${figureName !== 'Circle' ? rotation : ''}`] || null;
};

export type BackgroundFigureProps = {
    className?: string;
    color?: 'blue' | 'green' | 'orange' | 'purple' | 'red' | 'yellow';
    disableAnimation?: boolean;
    figure: 'Circle' | 'Connect' | 'Dial' | 'GoldenSection' | 'Swoop';
    flipHorizontal?: boolean;
    flipVertical?: boolean;
    horizontalOffset?: number;
    horizontalPosition?: 'left' | 'right';
    layerAdjust?: number;
    rotation?: '0' | '90' | '180' | '270';
    verticalOffset?: number;
    verticalPosition?: 'above' | 'below';
    width?: number;
};

export const BackgroundFigure: React.FC<BackgroundFigureProps> = (props) => {
    const [figureTop, setFigureTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const figureRef = useRef<HTMLDivElement>(null);
    const { scrollY } = useViewportScroll();

    useLayoutEffect(() => {
        if (!figureRef.current) {
            return;
        }
        setFigureTop(figureRef.current.offsetTop);
        setWindowHeight(window.innerHeight);
    });

    const {
        className,
        color,
        disableAnimation,
        figure,
        flipHorizontal,
        flipVertical,
        horizontalOffset,
        horizontalPosition,
        layerAdjust,
        rotation,
        verticalOffset,
        verticalPosition,
        width,
    } = props;

    const y =
        disableAnimation === true
            ? 0
            : useSpring(
                  useTransform(scrollY, [figureTop - windowHeight, figureTop], ['10vh', '-10vh'], { clamp: false })
              );

    const FigureSVG = getFigure(figure, rotation);

    const figureStyle: MotionStyle = { y };

    return (
        <div className={cx('fig', className)} ref={figureRef} style={{ zIndex: -10 + (layerAdjust || 0) }}>
            <motion.div style={figureStyle}>
                <FigureSVG
                    className={cx(
                        'fig-svg',
                        color && `is-color-${color || 'blue'}`,
                        horizontalPosition && `is-horizontal-${horizontalPosition || 'left'}`,
                        verticalPosition && `is-vertical-${verticalPosition || 'below'}`,
                        {
                            'is-flipped-horizontal': flipHorizontal === true,
                            'is-flipped-vertical': flipVertical === true,
                        }
                    )}
                    style={{
                        marginInlineStart: `${horizontalPosition !== 'right' ? horizontalOffset || 0 : 0}%`,
                        marginInlineEnd: `${horizontalPosition === 'right' ? horizontalOffset || 0 : 0}%`,
                        marginBlockStart: `${verticalPosition === 'below' ? verticalOffset || 0 : 0}%`,
                        marginBlockEnd: `${verticalPosition !== 'below' ? verticalOffset || 0 : 0}%`,
                        width: `${width || 100}%`,
                    }}
                />
            </motion.div>
        </div>
    );
};

export default BackgroundFigure;
