import React from 'react';

import { HeroBlockEntry } from '@/types/HeroBlock';
import Button from './Button';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import SubscribeForm from './SubscribeForm';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './HeroLead.module.scss';
const cx = classNames.bind(style);

export type HeroLeadLeftProps = {
    contentPosition: 'left' | 'right' | 'center';
    entry: HeroBlockEntry;
};

export const HeroLeadLeft: React.FC<HeroLeadLeftProps> = (props) => {
    const { contentPosition, entry } = props;
    const {
        attribution,
        attributionLink,
        image,
        topline,
        headline,
        copy,
        ctaLink,
        ctaTitle,
        signupForm,
        centered,
        skinny,
    } = entry.fields;

    return (
        <div className={cx('hero-lead', `hero-lead-${contentPosition}`)}>
            <Container className={cx('hero-content-container')}>
                <Grid className={cx('hero-content-grid')}>
                    {image && (
                        <div className={cx('hero-image')}>
                            <Image
                                className={cx('hero-image-img')}
                                src={image.fields.file.url}
                                alt={image.fields.title}
                                width={960}
                                size={image.fields.file.details.image}
                                fit="fill"
                                focus="faces"
                            />
                            {attribution && (
                                <p className={cx('attribution')}>
                                    {attributionLink ? (
                                        <a className={cx('attribution-link')} href={attributionLink}>
                                            {attribution}
                                        </a>
                                    ) : (
                                        attribution
                                    )}
                                </p>
                            )}
                        </div>
                    )}
                    <div className={cx('hero-content', { 'is-centered': centered, 'is-skinny': skinny })}>
                        {topline && <Topline>{topline}</Topline>}
                        {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                        {copy && <RichText document={copy} />}
                        {signupForm === true && <SubscribeForm />}
                        {ctaLink && ctaTitle && (
                            <Button href={ctaLink} color="blue">
                                {ctaTitle}
                            </Button>
                        )}
                    </div>
                </Grid>
            </Container>
        </div>
    );
};

export default HeroLeadLeft;
