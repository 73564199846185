import React, { useLayoutEffect } from 'react';

import { FeatureGroupEntry } from '@/types/FeatureGroup';
import { FeatureScrollConfig } from './FeaturesBlock';
import Blocks from './Blocks';
import Container from './Container';
import Row from './Row';

import Circle from '../images/shapes/circle.svg';
import Connect0 from '../images/shapes/connect-0.svg';
import Connect90 from '../images/shapes/connect-90.svg';
import Connect180 from '../images/shapes/connect-180.svg';
import Connect270 from '../images/shapes/connect-270.svg';
import Dial0 from '../images/shapes/dial-0.svg';
import Dial90 from '../images/shapes/dial-90.svg';
import Dial180 from '../images/shapes/dial-180.svg';
import Dial270 from '../images/shapes/dial-270.svg';
import GoldenSection0 from '../images/shapes/golden-section-0.svg';
import GoldenSection90 from '../images/shapes/golden-section-90.svg';
import GoldenSection180 from '../images/shapes/golden-section-180.svg';
import GoldenSection270 from '../images/shapes/golden-section-270.svg';
import Swoop0 from '../images/shapes/swoop-0.svg';
import Swoop90 from '../images/shapes/swoop-90.svg';
import Swoop180 from '../images/shapes/swoop-180.svg';
import Swoop270 from '../images/shapes/swoop-270.svg';

import classNames from 'classnames/bind';
import style from './FeatureGroup.module.scss';
const cx = classNames.bind(style);

const FIGURES = {
    Circle,
    Connect0,
    Connect90,
    Connect180,
    Connect270,
    Dial0,
    Dial90,
    Dial180,
    Dial270,
    GoldenSection0,
    GoldenSection90,
    GoldenSection180,
    GoldenSection270,
    Swoop0,
    Swoop90,
    Swoop180,
    Swoop270,
};

export type FeatureGroupProps = {
    featureGroup: FeatureGroupEntry;
    addFeature: (config: FeatureScrollConfig) => void;
    removeFeature: (id: string) => void;
};

export const FeatureGroup: React.FC<FeatureGroupProps> = (props) => {
    const featureRef = React.createRef<HTMLDivElement>();

    const { id } = props.featureGroup.sys;
    const { slug, title, features, color, figure } = props.featureGroup.fields;
    const FigureSVG = figure ? FIGURES[figure] : null;

    // Pass height to parent
    useLayoutEffect(() => {
        if (!featureRef.current) {
            return;
        }

        props.addFeature({
            color,
            height: featureRef.current.offsetHeight,
            id,
            slug,
            title,
        });

        return () => {
            props.removeFeature(id);
        };
    }, []);

    return (
        <div ref={featureRef} id={slug} className={cx('feature-group')}>
            <Container className={cx('feature-group-header', `is-${color}`)}>
                <Row justify="start" gap="sm">
                    {FigureSVG && <FigureSVG className={cx('feature-group-figure')} />}
                    <h1 className={cx('feature-group-title')}>{title}</h1>
                </Row>
            </Container>
            {features &&
                features.map((feature) => (
                    <div key={feature.sys.id} id={feature.fields.slug} className={cx('feature')}>
                        {feature.fields.blocks && <Blocks blocks={feature.fields.blocks} />}
                    </div>
                ))}
        </div>
    );
};

export default FeatureGroup;
