import React from 'react';

import classNames from 'classnames/bind';
import style from './Topline.module.scss';
const cx = classNames.bind(style);

export type ToplineProps = {
    className?: string;
};

export const Topline: React.FC<ToplineProps> = (props) => {
    const { className, children } = props;

    return <h2 className={cx('topline', className)}>{children}</h2>;
};

export default Topline;
