import React from 'react';

import { AnimationBlockEntry } from '@/types/AnimationBlock';
import Animation from './Animation';
import Button from './Button';
import Container from './Container';
import Headline from './Headline';
import RichText from './RichText';
import SubscribeForm from './SubscribeForm';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './AnimationWithOverlay.module.scss';
const cx = classNames.bind(style);

export type AnimationWithOverlayProps = {
    entry: AnimationBlockEntry;
};

export const AnimationWithOverlay: React.FC<AnimationWithOverlayProps> = (props) => {
    const {
        attribution,
        attributionLink,
        topline,
        headline,
        copy,
        ctaLink,
        ctaTitle,
        inverseContent,
        contentPosition,
        animationPosition,
        animationClass,
        signupForm,
    } = props.entry.fields;

    return (
        <>
            <div className={cx('animation-with-overlay', animationClass)}>
                <div className={cx('player', animationPosition && `player-position-${animationPosition}`)}>
                    <Animation entry={props.entry} />
                    {attribution && (
                        <p className={cx('attribution')}>
                            {attributionLink ? (
                                <a className={cx('attribution-link')} href={attributionLink}>
                                    {attribution}
                                </a>
                            ) : (
                                attribution
                            )}
                        </p>
                    )}
                </div>
                <div className={cx('overlay', contentPosition && `is-${contentPosition}`, { inverse: inverseContent })}>
                    <Container className={cx('overlay-container')}>
                        {topline && <Topline>{topline}</Topline>}
                        {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                        {copy && <RichText document={copy} />}
                        {signupForm === true && <SubscribeForm />}
                        {ctaLink && ctaTitle && <Button href={ctaLink}>{ctaTitle}</Button>}
                    </Container>
                </div>
            </div>
        </>
    );
};

export default AnimationWithOverlay;
