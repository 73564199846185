import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';

import { VideoBlockEntry } from '@/types/VideoBlock';
import Container from './Container';
import Video from './Video';

import classNames from 'classnames/bind';
import style from './VideoBlock.module.scss';
const cx = classNames.bind(style);

export type VideoBlockProps = {
    entry: VideoBlockEntry;
};

export const VideoBlock: React.FC<VideoBlockProps> = (props) => {
    const { video, vimeo, youtube } = props.entry.fields;

    return (
        <div className={cx('video-block')}>
            <Container>
                {video && <Video src={video.fields.file.url} controls={true} />}
                {vimeo && <Vimeo video={vimeo} responsive={true} color="0664ff" />}
                {youtube && (
                    <div className={cx('player-container')}>
                        <YouTube video={youtube} modestBranding={true} showRelatedVideos={false} showInfo={false} />
                    </div>
                )}
            </Container>
        </div>
    );
};

export default VideoBlock;
