import React from 'react';

import { AnimationBlockEntry } from '@/types/AnimationBlock';
import { BackgroundFigureBlockEntry } from '@/types/BackgroundFigureBlock';
import { BlogBlockEntry } from '@/types/BlogBlock';
import { CareersBlockEntry } from '@/types/CareersBlock';
import { FeatureBlock } from '@/types/Feature';
import { FeaturesBlockEntry } from '@/types/FeaturesBlock';
import { HeroBlockEntry } from '@/types/HeroBlock';
import { ImageBlockEntry } from '@/types/ImageBlock';
import { InvestorsBlockEntry } from '@/types/InvestorsBlock';
import { JobsBlockEntry } from '@/types/JobsBlock';
import { PageBlock } from '@/types/Page';
import { ShowcaseBlockEntry } from '@/types/ShowcaseBlock';
import { TeammatesBlockEntry } from '@/types/TeammatesBlock';
import { TestimonialsBlockEntry } from '@/types/TestimonialsBlock';
import { TextBlockEntry } from '@/types/TextBlock';
import { VideoBlockEntry } from '@/types/VideoBlock';
import AnimationBlock from './AnimationBlock';
import BackgroundFigureBlock from './BackgroundFigureBlock';
import BlogBlock from './BlogBlock';
import CareersBlock from './CareersBlock';
import FeaturesBlock from './FeaturesBlock';
import HeroBlock from './HeroBlock';
import ImageBlock from './ImageBlock';
import InvestorsBlock from './InvestorsBlock';
import JobsBlock from './JobsBlock';
import ShowcaseBlock from './ShowcaseBlock';
import TeammatesBlock from './TeammatesBlock';
import TestimonialsBlock from './TestimonialsBlock';
import TextBlock from './TextBlock';
import VideoBlock from './VideoBlock';

export type BlocksProps = {
    blocks: (PageBlock | FeatureBlock)[];
};

export const Blocks: React.FC<BlocksProps> = (props) => {
    const { blocks } = props;

    return (
        <div className="blocks">
            {blocks &&
                blocks.map((block, i) => {
                    // Deleted block
                    if (!block || !block.sys || !block.sys.contentType || !block.sys.contentType.sys) {
                        return null;
                    }

                    // Get block entry content type
                    const contentTypeId = block.sys.contentType.sys.id;

                    // Render block
                    switch (contentTypeId) {
                        case 'animationBlock':
                            return <AnimationBlock key={i} entry={block as AnimationBlockEntry} />;
                        case 'backgroundFigureBlock':
                            return <BackgroundFigureBlock key={i} entry={block as BackgroundFigureBlockEntry} />;
                        case 'blogBlock':
                            return <BlogBlock key={i} entry={block as BlogBlockEntry} />;
                        case 'careersBlock':
                            return <CareersBlock key={i} entry={block as CareersBlockEntry} />;
                        case 'featuresBlock':
                            return <FeaturesBlock key={i} entry={block as FeaturesBlockEntry} />;
                        case 'heroBlock':
                            return <HeroBlock key={i} entry={block as HeroBlockEntry} />;
                        case 'imageBlock':
                            return <ImageBlock key={i} entry={block as ImageBlockEntry} />;
                        case 'investorsBlock':
                            return <InvestorsBlock key={i} entry={block as InvestorsBlockEntry} />;
                        case 'jobsBlock':
                            return <JobsBlock key={i} entry={block as JobsBlockEntry} />;
                        case 'showcaseBlock':
                            return <ShowcaseBlock key={i} entry={block as ShowcaseBlockEntry} />;
                        case 'teammatesBlock':
                            return <TeammatesBlock key={i} entry={block as TeammatesBlockEntry} />;
                        case 'testimonialsBlock':
                            return <TestimonialsBlock key={i} entry={block as TestimonialsBlockEntry} />;
                        case 'textBlock':
                            return <TextBlock key={i} entry={block as TextBlockEntry} />;
                        case 'videoBlock':
                            return <VideoBlock key={i} entry={block as VideoBlockEntry} />;
                        default:
                            return (
                                <div key={i}>
                                    <strong>🚨 Unknown block type:</strong> <code>{contentTypeId}</code>
                                </div>
                            );
                    }
                })}
        </div>
    );
};

export default Blocks;
