import { useInView } from 'react-intersection-observer';
import Lottie, { Options } from 'react-lottie';
import React, { CSSProperties, useState, useEffect } from 'react';
import useMedia from 'use-media';

import { AnimationBlockEntry, AnimationData } from '@/types/AnimationBlock';

import classNames from 'classnames/bind';
import style from './Animation.module.scss';
const cx = classNames.bind(style);

export type AnimationProps = {
    className?: string;
    disableLayoutPreservation?: boolean;
    entry: AnimationBlockEntry;
    isPaused?: boolean;
    preserveAspectRatio?: string;
    smallScreenMaxWidth?: string;
};

export const Animation: React.FC<AnimationProps> = (props) => {
    const [loading, setLoading] = useState(true);
    const [transformedAnimData, setTransformedAnimData] = useState<AnimationData | undefined>(undefined);
    const [transformedAnimDataSmall, setTransformedAnimDataSmall] = useState<AnimationData | undefined>(undefined);
    const [playerRef, playerInView] = useInView();
    const smallScreen = useMedia({ maxWidth: props.smallScreenMaxWidth || '479px' });

    useEffect(() => {
        void getAnimationData();
    }, [smallScreen]);

    const getAnimationData = async () => {
        const { animationData, animationDataSmall } = props.entry.fields;

        // Check for cached data
        const transformedData = smallScreen ? transformedAnimDataSmall : transformedAnimData;
        if (transformedData) {
            return;
        }

        // Loading
        setLoading(true);
        const animationDataFile = smallScreen && animationDataSmall ? animationDataSmall : animationData;
        const setAnimationDataFunc =
            smallScreen && animationDataSmall ? setTransformedAnimDataSmall : setTransformedAnimData;

        // Save transformed data to cache
        try {
            const response = await fetch(animationDataFile.fields.file.url);
            const json: AnimationData = await response.json();
            setAnimationDataFunc(transformAnimationData(json));
            setLoading(false);
        } catch (e) {
            console.error('Error loading animation data!', e);
            setLoading(false);
        }
    };

    const animationStyles = (): CSSProperties => {
        const { disableLayoutPreservation } = props;
        if (disableLayoutPreservation === true) {
            return {};
        }

        const { width, height, smallScreenWidth, smallScreenHeight } = props.entry.fields;

        const w = smallScreen ? smallScreenWidth : width;
        const h = smallScreen ? smallScreenHeight : height;

        const paddingBottom = !w || !h ? 'unset' : `${(h / w) * 100}%`;
        return { paddingBottom };
    };

    const animationOptions = (): Options => {
        const { preserveAspectRatio } = props;

        return {
            loop: true,
            autoplay: true,
            animationData: smallScreen ? transformedAnimDataSmall : transformedAnimData,
            rendererSettings: {
                preserveAspectRatio: preserveAspectRatio || 'xMinYMin slice',
            },
        };
    };

    /**
     * Replace exported asset paths in given animation data to use Contentful hosted assets
     */
    const transformAnimationData = (data: AnimationData) => {
        const { animationImages } = props.entry.fields;
        if (!data || !data.assets || !animationImages) {
            return transformedAnimData;
        }

        return {
            ...data,
            assets: data.assets.map((asset) => {
                // Not a hosted asset
                if (!asset.p || !asset.u) {
                    return asset;
                }

                // Get matching hosted asset URL
                const hostedImage = animationImages.filter((image) => asset.p!.startsWith(image.fields.file.fileName));
                if (!hostedImage.length) {
                    return asset;
                }
                const hostedImageUrl = hostedImage[0].fields.file.url;
                const hostedImagePath = hostedImageUrl.substr(0, hostedImageUrl.lastIndexOf('/') + 1);

                // Transform asset URL
                return {
                    ...asset,
                    u: hostedImagePath,
                };
            }),
        };
    };

    const { className, isPaused } = props;

    return (
        <div ref={playerRef} className={cx('animation', className)} style={animationStyles()}>
            {!loading && transformedAnimData && (
                <Lottie
                    options={animationOptions()}
                    isClickToPauseDisabled={true}
                    isPaused={isPaused || !playerInView}
                />
            )}
        </div>
    );
};

export default Animation;
