import clamp from 'lodash.clamp';
import React from 'react';

import { TestimonialsBlockEntry } from '@/types/TestimonialsBlock';
import Button from './Button';
import Container from './Container';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import Topline from './Topline';

import Quote from '../images/shapes/quote.svg';

import classNames from 'classnames/bind';
import style from './TestimonialsBlock.module.scss';
const cx = classNames.bind(style);

export type TestimonialsBlockProps = {
    entry: TestimonialsBlockEntry;
};

const squareAspect = {
    height: 1,
    width: 1,
};

export const TestimonialsBlock: React.FC<TestimonialsBlockProps> = (props) => {
    const { ctaLink, ctaTitle, headline, testimonials, topline } = props.entry.fields;

    const colCount = clamp(testimonials?.length ?? 0, 1, 4);

    return (
        <div className={cx('testimonials-block')}>
            <Container>
                {topline && <Topline>{topline}</Topline>}
                {headline && <Headline>{headline}</Headline>}
                {testimonials && (
                    <div className={cx('testimonials', { [`col-${colCount}up`]: colCount > 1 })}>
                        {testimonials.map((testimonial) => (
                            <div key={testimonial.sys.id} className={cx('testimonial')}>
                                <div className={cx('graphic')}>
                                    <Quote className={cx('quotes')} />
                                    {testimonial.fields.image && (
                                        <Image
                                            className={cx('image')}
                                            src={testimonial.fields.image.fields.file.url}
                                            alt={testimonial.fields.image.fields.title}
                                            width={256}
                                            height={256}
                                            size={squareAspect}
                                            fit="fill"
                                            focus="faces"
                                            round={true}
                                        />
                                    )}
                                </div>
                                <div>
                                    <div className={cx('text')}>
                                        <RichText document={testimonial.fields.quote} />
                                    </div>
                                    <div className={cx('byline')}>
                                        {testimonial.fields.link ? (
                                            <a href={testimonial.fields.link}>{testimonial.fields.byline}</a>
                                        ) : (
                                            <span>{testimonial.fields.byline}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {ctaLink && ctaTitle && <Button href={ctaLink}>{ctaTitle}</Button>}
            </Container>
        </div>
    );
};

export default TestimonialsBlock;
