import React from 'react';

import { HeroBlockEntry } from '@/types/HeroBlock';
import HeroCallToAction from './HeroCallToAction';
import HeroLead from './HeroLead';
import HeroSplash from './HeroSplash';

export type HeroBlockProps = {
    entry: HeroBlockEntry;
};

export const HeroBlock: React.FC<HeroBlockProps> = (props) => {
    const { entry } = props;
    const { style } = entry.fields;

    if (style === 'SplashImage') {
        return <HeroSplash entry={entry} />;
    } else if (style === 'CallToAction') {
        return <HeroCallToAction entry={entry} />;
    } else if (style === 'LeadLeft') {
        return <HeroLead entry={entry} contentPosition="left" />;
    } else if (style === 'LeadRight') {
        return <HeroLead entry={entry} contentPosition="right" />;
    } else if (style === 'LeadCenter') {
        return <HeroLead entry={entry} contentPosition="center" />;
    } else {
        return (
            <div>
                <strong>🚨 Unknown hero style:</strong> <code>{style}</code>
            </div>
        );
    }
};

export default HeroBlock;
