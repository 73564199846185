import React from 'react';

import { TextBlockEntry } from '@/types/TextBlock';
import Button from './Button';
import Container from './Container';
import Headline from './Headline';
import RichText from './RichText';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './TextBlock.module.scss';
const cx = classNames.bind(style);

export type TextBlockProps = {
    entry: TextBlockEntry;
};

export const TextBlock: React.FC<TextBlockProps> = (props) => {
    const { copy, ctaLink, ctaTitle, headline, topline, bookmark } = props.entry.fields;

    // TODO: refactor to allow button color to be specified by contentful
    return (
        <div id={bookmark} className={cx('text-block')}>
            <Container>
                {topline && <Topline>{topline}</Topline>}
                {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                {copy && <RichText document={copy} />}
                {ctaLink && ctaTitle && (
                    <Button href={ctaLink} color="rainbow">
                        {ctaTitle}
                    </Button>
                )}
            </Container>
        </div>
    );
};

export default TextBlock;
