import { motion, useViewportScroll, MotionStyle, useTransform } from 'framer-motion';
import React, { useState, useEffect } from 'react';

import { FeaturesBlockEntry } from '@/types/FeaturesBlock';
import Button from './Button';
import FeatureGroup from './FeatureGroup';

import classNames from 'classnames/bind';
import style from './FeaturesBlock.module.scss';
const cx = classNames.bind(style);

export type FeatureScrollConfig = {
    color: 'blue' | 'red' | 'purple';
    height: number;
    id: string;
    slug: string;
    title: string;
};

export type FeaturesBlockProps = {
    entry: FeaturesBlockEntry;
};

export const FeaturesBlock: React.FC<FeaturesBlockProps> = (props) => {
    const [features, setFeatures] = useState<FeatureScrollConfig[]>([]);
    const [featuresHeight, setFeaturesHeight] = useState(0);
    const { scrollY } = useViewportScroll();

    const addFeature = (feature: FeatureScrollConfig) => setFeatures((features) => features.concat(feature));
    const removeFeature = (id: string) => setFeatures(features.filter((f) => f.id !== id));

    useEffect(() => {
        if (!document.scrollingElement) {
            return;
        }
        setFeaturesHeight(features.reduce((total, feature) => total + feature.height, 0));
    }, [features]);

    const scroll = useTransform(scrollY, [0, featuresHeight], ['0%', '100%']);
    const fillStyles: MotionStyle = { height: scroll };

    const { featureGroups } = props.entry.fields;

    return (
        <div className={cx('features-block')}>
            {featureGroups &&
                featureGroups.map((featureGroup) => (
                    <FeatureGroup
                        key={featureGroup.sys.id}
                        featureGroup={featureGroup}
                        addFeature={addFeature}
                        removeFeature={removeFeature}
                    />
                ))}
            <nav className={cx('features-nav')}>
                <div className={cx('features-nav-bar-empty')}>
                    {features &&
                        features.map((feature) => (
                            <div
                                key={`fnbe-${feature.id}`}
                                className={cx('feature-nav-item-empty')}
                                style={{ flexBasis: `${(feature.height / featuresHeight) * 100}%` }}
                            >
                                <Button
                                    className={cx('feature-nav-btn', `is-${feature.color}`)}
                                    href={`#${feature.slug}`}
                                >
                                    <div className={cx('feature-nav-btn-tooltip')}>{feature.title}</div>
                                </Button>
                            </div>
                        ))}
                </div>
                <motion.div className={cx('features-nav-bar-fill-mask')} style={fillStyles}>
                    <div className={cx('features-nav-bar-fill')}>
                        {features &&
                            features.map((feature) => (
                                <div
                                    key={`fnbf-${feature.id}`}
                                    className={cx('feature-nav-item-fill', `is-${feature.color}`)}
                                    style={{ flexBasis: `${(feature.height / featuresHeight) * 100}%` }}
                                >
                                    <div className={cx('feature-nav-btn-fill', `is-${feature.color}`)}></div>
                                </div>
                            ))}
                    </div>
                </motion.div>
            </nav>
        </div>
    );
};

export default FeaturesBlock;
