import React from 'react';

import { ImageBlockEntry } from '@/types/ImageBlock';
import Button from './Button';
import Container from './Container';
import Grid from './Grid';
import Headline from './Headline';
import Image from './Image';
import RichText from './RichText';
import Topline from './Topline';

import classNames from 'classnames/bind';
import style from './ImageBlock.module.scss';
const cx = classNames.bind(style);

export type ImageBlockProps = {
    entry: ImageBlockEntry;
};

export const ImageBlock: React.FC<ImageBlockProps> = (props) => {
    const { attribution, attributionLink, caption, ctaLink, ctaTitle, headline, image, position, topline } =
        props.entry.fields;

    return (
        <div className={cx('image-block', { 'image-block-wide': position === 'Wide' })}>
            {image && position === 'Wide' && (
                <div className={cx('image', `is-${position}`)}>
                    <Image
                        className={cx('image-img')}
                        src={image.fields.file.url}
                        alt={image.fields.title}
                        width={3840}
                        size={image.fields.file.details.image}
                        fit="fill"
                        focus="faces"
                    />
                    {attribution && (
                        <p className={cx('attribution')}>
                            {attributionLink ? (
                                <a className={cx('attribution-link')} href={attributionLink}>
                                    {attribution}
                                </a>
                            ) : (
                                attribution
                            )}
                        </p>
                    )}
                </div>
            )}
            <Container>
                <Grid className={cx('image-grid')}>
                    {image && position !== 'Wide' && (
                        <div className={cx('image', `is-${position}`)}>
                            <Image
                                className={cx('image-img')}
                                src={image.fields.file.url}
                                alt={image.fields.title}
                                width={960}
                                size={image.fields.file.details.image}
                                fit="fill"
                                focus="faces"
                            />
                            {attribution && <p className={cx('attribution')}>{attribution}</p>}
                        </div>
                    )}
                    <div className={cx('image-caption')}>
                        {topline && <Topline>{topline}</Topline>}
                        {headline && <Headline className={cx('headline')}>{headline}</Headline>}
                        {caption && <RichText document={caption} />}
                        {ctaLink && ctaTitle && <Button href={ctaLink}>{ctaTitle}</Button>}
                    </div>
                </Grid>
            </Container>
        </div>
    );
};

export default ImageBlock;
