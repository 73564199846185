import React from 'react';

import { AnimationBlockEntry } from '@/types/AnimationBlock';
import AnimationAppScroller from './AnimationAppScroller';
import AnimationWithOverlay from './AnimationWithOverlay';

export type AnimationBlockProps = {
    entry: AnimationBlockEntry;
};

export const AnimationBlock: React.FC<AnimationBlockProps> = (props) => {
    const { animationClass } = props.entry.fields;
    const animationClasses = animationClass ? animationClass.split(' ') : [];

    if (animationClasses.includes('app-scroller')) {
        return <AnimationAppScroller entry={props.entry} />;
    } else {
        return <AnimationWithOverlay entry={props.entry} />;
    }
};

export default AnimationBlock;
