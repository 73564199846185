import { motion, useTransform, useViewportScroll, MotionStyle } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import useMedia from 'use-media';

import { AnimationBlockEntry } from '@/types/AnimationBlock';
import { Asset } from '@/types/Contentful';
import { AnalyticsEvent } from '../analytics';
import Animation from './Animation';
import BackgroundFigure from './BackgroundFigure';
import Button from './Button';
import Container from './Container';
import Headline from './Headline';
import RichText from './RichText';
import SubscribeForm from './SubscribeForm';
import Topline from './Topline';

import Checkbox from '../images/icons/checkbox.svg';

import classNames from 'classnames/bind';
import style from './AnimationAppScroller.module.scss';
const cx = classNames.bind(style);

const START_SCROLLING_THRESHOLD = 1;
const STAGE_ONE_TARGET_SCREEN_HEIGHT = 0.7;
const STAGE_TWO_TARGET_SCREEN_HEIGHT = 0.8;

export type AnimationAppScrollerProps = {
    entry: AnimationBlockEntry;
};

export const AnimationAppScroller: React.FC<AnimationAppScrollerProps> = (props) => {
    const [stage, setStage] = useState(0);
    const [stageOneTarget, setStageOneTarget] = useState(756);
    const [stageTwoTarget, setStageTwoTarget] = useState(864);
    const [ghostWidthOffset, setGhostWidthOffset] = useState('0%');
    const [ghostMarginOffset, setGhostMarginOffset] = useState('0%');
    const { scrollY } = useViewportScroll();
    const smallScreen = useMedia({ maxWidth: '767px' });
    const isPortrait = useMedia({ orientation: 'portrait' });
    const [vh, setVh] = useState<string | null>(null);

    // Calculate offsets on resize
    //  - Move expensive calculations here as they're called less frequently
    const measure = () => {
        const { innerHeight, innerWidth } = window;

        // NOTE: don't want to recompute this on every measure, since the toolbar can disappear.
        if (vh === null) {
            const _vh = `${innerHeight * 0.01}px`;
            // Set global CSS variable to calculate proper 1vh
            //  - Allows CSS to reference var(--vh), which takes into account mobile browser toolbar heights
            //  - Source: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            document.documentElement.style.setProperty('--vh', _vh);
            setVh(_vh);
        }

        // Update window height
        setStageOneTarget(innerHeight * STAGE_ONE_TARGET_SCREEN_HEIGHT);
        setStageTwoTarget(innerHeight * STAGE_TWO_TARGET_SCREEN_HEIGHT);

        // Calculate width & margin offsets to transform the ghost image into square aspect
        const widthOffsetPercent = (innerHeight / innerWidth) * 100;
        setGhostWidthOffset(`${widthOffsetPercent}%`);

        // TODO: obviously there is a better way to do this...
        // These #s come from a best LM fit of margin-left ~ widthOffsetPercent - luckily this is
        // actually a linear relationship.
        /*
         *    lm(formula = ml ~ hw, data = test)
         *
         *    Residuals:
         *         Min       1Q   Median       3Q      Max
         *    -0.32420 -0.04290  0.01452  0.06800  0.17858
         *
         *    Coefficients:
         *                 Estimate Std. Error t value Pr(>|t|)
         *    (Intercept)  9.059572   0.125611   72.12 6.40e-15 ***
         *    hw          -0.089033   0.001051  -84.74 1.28e-15 ***
         *    ---
         *    Signif. codes:  0 ‘***’ 0.001 ‘**’ 0.01 ‘*’ 0.05 ‘.’ 0.1 ‘ ’ 1
         *
         *    Residual standard error: 0.1469 on 10 degrees of freedom
         *    Multiple R-squared:  0.9986,	Adjusted R-squared:  0.9985
         *    F-statistic:  7181 on 1 and 10 DF,  p-value: 1.28e-15
         */
        setGhostMarginOffset(`${9.059572 + -0.089033 * widthOffsetPercent}%`);
    };

    // Measure on window resize/orientation change
    useEffect(() => {
        // Initial measure
        measure();

        // Measure on resize
        window.addEventListener('resize', measure);

        // Update animation stage on scroll
        const unsubscribeScrollChange = scrollY.onChange((y) => {
            if (y >= window.innerHeight * STAGE_TWO_TARGET_SCREEN_HEIGHT) {
                setStage(3);
            } else if (y >= window.innerHeight * STAGE_ONE_TARGET_SCREEN_HEIGHT) {
                setStage(2);
            } else if (y >= START_SCROLLING_THRESHOLD) {
                setStage(1);
            } else {
                setStage(0);
            }
        });

        return () => {
            unsubscribeScrollChange();
            window.removeEventListener('resize', measure);
        };
    }, []);

    // Get additional image asset data
    const { additionalImages } = props.entry.fields;
    const ghostImage: Asset | undefined =
        additionalImages && additionalImages.length >= 1 ? additionalImages[0] : undefined;
    const appImageLg: Asset | undefined =
        additionalImages && additionalImages.length >= 2 ? additionalImages[1] : undefined;
    const appImageSm: Asset | undefined =
        additionalImages && additionalImages.length >= 3 ? additionalImages[2] : undefined;

    // Keyframe data
    const stageOneRange = [START_SCROLLING_THRESHOLD, stageOneTarget];
    const stageTwoRange = [stageOneTarget, stageTwoTarget];

    // Ghost styles
    const ghostStyle: MotionStyle = {
        scale: useTransform(scrollY, stageOneRange, [1, smallScreen ? 1 : 0.18098]),
        marginLeft: useTransform(scrollY, stageOneRange, ['0%', smallScreen ? '0%' : ghostMarginOffset]),
        width: useTransform(scrollY, stageOneRange, ['100%', smallScreen ? '100%' : ghostWidthOffset]),
    };
    const ghostHeadStyle: MotionStyle = {
        opacity: useTransform(scrollY, stageTwoRange, [0, 1]),
    };

    // App styles
    const appImgStyle: MotionStyle = {
        scale: useTransform(scrollY, stageOneRange, [1.5, 1]),
        translateY: useTransform(scrollY, stageOneRange, ['50%', '0%']),
    };

    const {
        attribution,
        attributionLink,
        topline,
        headline,
        copy,
        ctaLink,
        ctaTitle,
        inverseContent,
        contentPosition,
        signupForm,
    } = props.entry.fields;

    return (
        <>
            <div className={cx('scroller', { 'is-scrolled': stage >= 1 })}>
                <div className={cx('anim')}>
                    <Animation
                        className={cx('anim-player')}
                        entry={props.entry}
                        preserveAspectRatio="xMaxYMax slice"
                        disableLayoutPreservation={true}
                        smallScreenMaxWidth="767px"
                    />
                    <div
                        className={cx('overlay', contentPosition && `is-${contentPosition}`, {
                            inverse: inverseContent,
                        })}
                    >
                        <Container className={cx('overlay-container')}>
                            {topline && <Topline>{topline}</Topline>}
                            {headline && <Headline>{headline}</Headline>}
                            {copy && <RichText className={cx('overlay-text')} document={copy} />}
                            {signupForm && <SubscribeForm />}
                            {ctaLink && ctaTitle && (
                                <Button analyticEvent={AnalyticsEvent.Signup} align="left" href={ctaLink} color="light">
                                    {ctaTitle}
                                </Button>
                            )}
                        </Container>
                    </div>
                    {ghostImage && (
                        <motion.div className={cx('ghost')} style={ghostStyle}>
                            <motion.div className={cx('ghost-head')} style={ghostHeadStyle}>
                                <Checkbox className={cx('ghost-head-checkbox')} />
                            </motion.div>
                            <svg
                                className={cx('ghost-svg')}
                                viewBox={smallScreen ? '0 0 1125 2168' : '0 0 1440 850'}
                                width={smallScreen ? '1125' : '1440'}
                                height={smallScreen ? '2168' : '850'}
                                preserveAspectRatio="xMaxYMax slice"
                            >
                                <defs>
                                    <clipPath id="__ghost_img_el">
                                        <rect
                                            width={smallScreen ? '1125' : '1440'}
                                            height={smallScreen ? '2168' : '850'}
                                            x="0"
                                            y="0"
                                        />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#__ghost_img_el)">
                                    <g
                                        transform={
                                            smallScreen
                                                ? 'matrix(0.5699999928474426,0,0,0.5699999928474426,-1281.219970703125,-9.47998046875)'
                                                : 'matrix(0.25,0,0,0.25,-1,-104)'
                                        }
                                        opacity="1"
                                    >
                                        <image
                                            width="5792px"
                                            height="3828px"
                                            preserveAspectRatio="xMidYMid slice"
                                            href={ghostImage.fields.file.url}
                                        />
                                    </g>
                                </g>
                            </svg>
                        </motion.div>
                    )}
                </div>
                <BackgroundFigure
                    figure="Swoop"
                    color="red"
                    width={20}
                    horizontalPosition="right"
                    horizontalOffset={0}
                    verticalPosition="below"
                    verticalOffset={-5}
                />
                <BackgroundFigure
                    figure="Circle"
                    color="purple"
                    width={33}
                    horizontalPosition="left"
                    horizontalOffset={-5}
                    verticalPosition="below"
                    verticalOffset={35}
                />
                <div className={cx('app')}>
                    {!isPortrait && appImageLg && (
                        <motion.img className={cx('app-img')} style={appImgStyle} src={appImageLg.fields.file.url} />
                    )}
                    {isPortrait && appImageSm && (
                        <motion.img className={cx('app-img')} style={appImgStyle} src={appImageSm.fields.file.url} />
                    )}
                    {attribution && (
                        <p className={cx('attribution')}>
                            {attributionLink ? (
                                <a className={cx('attribution-link')} href={attributionLink}>
                                    {attribution}
                                </a>
                            ) : (
                                attribution
                            )}
                        </p>
                    )}
                </div>
            </div>
            <div className={cx('lead')}>
                <Headline className={cx('lead-headline')}>
                    Facet is the first AI-native, API-first image editor.
                </Headline>
            </div>
        </>
    );
};

export default AnimationAppScroller;
